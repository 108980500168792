import * as React from 'react';
import styled, { css } from 'styled-components';
import { StaticImage } from "gatsby-plugin-image";
import Layout from '../components/Layout';
import Seo from '../components/seo';
import TopPanel from '../components/TopPanel';
import { Section } from '../components/section';
import { ContentWrapper } from '../components/common';
import { Title2, TitleWrapper, SubText } from '../components/Headings';
import GalleryPanel from "../components/GalleryPanel";
import transportSvg from '../images/svg/transport.svg';
import cartSvg from '../images/svg/cart.svg';
import educationSvg from '../images/svg/education.svg';
import healthcareSvg from '../images/svg/healthcare.svg';
import cultureSvg from '../images/svg/culture.svg';
import sportSvg from '../images/svg/sport.svg';
import Map from '../components/Map';
import downloadSrc from '../images/svg/download.svg';

const iconStyle = css`
  fill: ${({theme}) => theme.colors.icon};
  width: 60px;
  height: auto;
`;

const TransportIcon = styled(transportSvg)`
  ${iconStyle};
`;

const CartIcon = styled(cartSvg)`
  ${iconStyle};
`;

const EducationIcon = styled(educationSvg)`
  ${iconStyle};
`;

const HealthcareIcon = styled(healthcareSvg)`
  ${iconStyle};
`;

const CultureIcon = styled(cultureSvg)`
  ${iconStyle};
`;

const SportIcon = styled(sportSvg)`
  ${iconStyle};
`;

const LargeText = styled.p`
  text-align: left;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  padding-right: 2rem;

  @media(min-width: 768px) {
    text-align: right;
  }
`;


const ListSection = styled(Section)`
  ${LargeText} {
    text-align: left;
    margin-bottom: 2rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 1.125rem;

    li {
      padding-left: 1rem;
      position: relative;
    }

    li:before {
      content: '-';
      position: absolute;
      left: 0px;
    }

    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

const PinkSection = styled(Section)`
  background-color: ${({theme}) => theme.colors.primary};

  ${TitleWrapper} {
    margin-bottom: 70px;
  }

  ${Title2}, ${SubText} {
    color: ${({theme}) => theme.colors.white};
  }
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0.75rem;

  @media (min-width: 992px) {
    margin-bottom: 1.5rem;
  }
`;

const IconLabel = styled.span`
  display: block;
  font-family: 'Oswald', Georgia, sans-serif;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  margin-bottom: 1rem;

  @media (min-width: 992px) {
    font-size: 1.375rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.5rem;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  row-gap: 2rem;

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const DownloadBtn = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  text-align: center;
  text-decoration: none;
  margin-top: 2rem;


  span {
    display: block;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.white};
    padding: 1rem;
    text-transform: uppercase;
    border: 1px solid ${({theme}) => theme.colors.primary};
    transition: all 0.5s ease;
    margin-left: 1rem;
  }

  &:hover {
    text-decoration: none;

    span {
      color: ${({theme}) => theme.colors.primary};
      background-color: transparent;
    }

  }
`;

const DownloadIcon = styled(downloadSrc)`
  width: 20px;
  height: auto;
  fill: #303030;
  margin-bottom: 0.5rem;

  /* &:hover { */
    fill: ${({theme}) => theme.colors.primary};
  /* } */
`;

// TODO - btn link
function LocationPage() {
  return (
    <Layout>
      <Seo title="Lokalita" />
      <TopPanel
        title="Objevte jedno z&nbsp;nejlepších míst v&nbsp;Kolíně"
        text="Navíc strategicky výborné v&nbsp;rámci celého Česka. Rezidenční projekt Třešňovka představuje jedinečné propojení života v&nbsp;klidné čtvrti nedaleko přírody a&nbsp;všech výhod okresního města poblíž důležitých dopravních tepen."
        >
        <StaticImage src="../images/panel-04-n.jpg" className="panel-image" placeholder="blurred" alt="Foto" />
      </TopPanel>
      <Section>
        <ContentWrapper>
          <TitleWrapper>
            <Title2>Únik z&nbsp;ruchu města</Title2>
          </TitleWrapper>
          <div className="row">
            <div className="col-md-5 offset-md-1">
              <LargeText>
                Dostatek soukromí, dobrá dostupnost a&nbsp;blízkost přírody = ideální zázemí pro kvalitní vyvážení pracovního a&nbsp;soukromého času.
              </LargeText>
            </div>
            <div className="col">
              <p>Rezidence se nachází 5&nbsp;minut od historického centra Kolína a&nbsp;přímo u&nbsp;výjezdové komunikace na Prahu (dojezdová vzdálenost je asi 40&nbsp;minut). Zároveň ale poskytuje klidné zázemí a&nbsp;atmosféru sousedící vilové čtvrti. Blízko je rozhledna Vodárna a&nbsp;malebný třešňový sad, po němž Třešňovka nese své jméno.</p>
              <DownloadBtn href={`/data/situacni-vykres.pdf`} title="Stáhnout situační výkres v PDF" target="_blank" rel="noreferrer">
                <DownloadIcon />
                <span>Prohlédněte si situační výkres</span>
              </DownloadBtn>
            </div>
          </div>
        </ContentWrapper>
      </Section>
      <GalleryPanel folder="index" data={[1,2,3,4,5,6,7,8]} />
      <PinkSection>
        <ContentWrapper>
          <TitleWrapper>
            <Title2>Vše důležité na dosah</Title2>
            <SubText>Okolí disponuje vším, co okresní město nabízí ke spokojenému životu.</SubText>
          </TitleWrapper>
          <IconGrid>
            <Item>
              <IconWrapper>
                <TransportIcon />
              </IconWrapper>
              <IconLabel>Mhd</IconLabel>
              <span>Hned u&nbsp;vchodu do areálu se nachází zastávka Lučební, z&nbsp;níž je to pár minut i&nbsp;na meziměstské spoje.</span>
            </Item>
            <Item>
              <IconWrapper>
                <CartIcon />
              </IconWrapper>
              <IconLabel>Obchody</IconLabel>
              <span>V&nbsp;těsném sousedství je supermarket Lidl a&nbsp;do obchodního centra Futurum to není ani 5&nbsp;minut jízdy.</span>
            </Item>
            <Item>
              <IconWrapper>
                <EducationIcon />
              </IconWrapper>
              <IconLabel>Vzdělání</IconLabel>
              <span>Město disponuje množstvím kvalitních vzdělávacích institucí od mateřských školek až po vysoké školy různého zaměření.</span>
            </Item>
            <Item>
              <IconWrapper>
                <HealthcareIcon />
              </IconWrapper>
              <IconLabel>Zdravotní péče</IconLabel>
              <span>V&nbsp;dojezdové vzdálenosti je dostupná veškerá zdravotní péče, mimo jiné i&nbsp;oblastní nemocnice.</span>
            </Item>
            <Item>
              <IconWrapper>
                <CultureIcon />
              </IconWrapper>
              <IconLabel>Kultura</IconLabel>
              <span>Kolín je město s&nbsp;bohatou historií a&nbsp;množstvím příležitostí ke kulturnímu vyžití, např. v&nbsp;podobě koncertů, filmových promítání, festivalů apod.</span>
            </Item>
          </IconGrid>
        </ContentWrapper>
      </PinkSection>
      <ListSection>
        <ContentWrapper>
          <div className="row">
            <div className="col-md-5">
              <Title2>Skvělá dopravní dostupnost</Title2>
              <LargeText>
                Rezidence Třešňovka se nachází na strategickém místě s&nbsp;výbornou dostupností jak do centra Kolína, tak do Prahy, ale i&nbsp;bližšího okolí.
              </LargeText>
              <ul>
                <li>autem jste v&nbsp;centru Kolína za 5&nbsp;minut, v&nbsp;Praze cca za 40&nbsp;minut</li>
                <li>autobus MHD vás vyzvedne hned u&nbsp;vstupu do areálu</li>
              </ul>
            </div>
            <div className="col-md-6 offset-md-1">
            <Map />
            </div>
          </div>
        </ContentWrapper>
      </ListSection>
    </Layout>
  );
}

export default LocationPage;